$(document).ready(function(){
  $('#form').on('submit', function(e) {
    e.preventDefault()
    var formData = $(this).serialize();
    $.ajax({
      type: "POST",
      url: "php/form.php",
      data: formData,
      success: function() {

        $(this).trigger('reset');

        $('.order-done').fadeIn();
        setTimeout(function () {
          $('.order-done').fadeOut()
        },2000);

        setTimeout(function () {
          $('.popup').removeClass('popup_visible')
        }, 2000);
      },
      error: function (xhr,status,error) {
        console.log(status);
        console.log(error);
      }
    });

  });
});