$(function (){

  var galleryList = $('.gallery__list');

  if(galleryList.length){
    galleryList.slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      arrows: true,
      appendArrows: $('.slider-arrow'),
      prevArrow: $('.slider-arrows__prev'),
      nextArrow: $('.slider-arrows__next')
    });
  }
}());



