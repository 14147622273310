$(function (){

  var galleryLink = $(".gallery__link");

  if (galleryLink.length) {
    galleryLink.fancybox({
      openEffect	: 'none',
      closeEffect	: 'none',

      helpers:  {
        overlay : {
          css : {
            'background' : 'rgba(43, 53, 98, 0.9)'
          }
        }
      }
    });
  }
}());

