$(document).ready(function () {

  $('.tabs__control-link').on('click', function (e) {
    e.preventDefault();

    var item = $(this).closest('.tabs__control-item');
    var contentItem = $('.tabs__item');
    var itemPosition = item.data('class');

    contentItem.filter('.tabs__item_' + itemPosition)
      .add(item)
      .addClass('tabs__item_active')
      .siblings()
      .removeClass('tabs__item_active');

  });

  //табы на странице оборудования
  $(function () {
    $('.equipment-sections__head-item').on('click', function () {
      var item = $(this);
      var contentItem = $('.equipment-sections__body-wrap');
      var itemPosition = item.index();

      contentItem.eq(itemPosition).addClass('equipment-sections__body-wrap_active').siblings().removeClass('equipment-sections__body-wrap_active');

      item.addClass('equipment-sections__head-item_active').siblings().removeClass('equipment-sections__head-item_active')
    });
  });


  $(function () {
    $('.show-form').on('click', function (e) {
      e.preventDefault();

      $('.popup').addClass('popup_visible');
    });

    $('.order__close').on('click', function () {

      $('.popup').removeClass('popup_visible');
    });

  }());

  $(function () {
    $('.slide-down').on('click', function (){
      var scrollElem = $(this).attr('href');
      if ($(scrollElem).length != 0) {
        $('html, body').animate({ scrollTop: $(scrollElem).offset().top }, 500);
      }
      return false;
    });
  }());
});

var map;
function initMap() {
  var myLatLng = {lat: 55.365688, lng: 43.846643};

  map = new google.maps.Map(document.getElementById('map'), {
    center: {lat: 55.366250, lng: 43.842144},
    scrollwheel: false,
    zoom: 17
  });

  var marker = new google.maps.Marker({
    map: map,
    position: myLatLng,
    title: 'Студия автодетейлинга "Fast&Shine"'
  });
}
