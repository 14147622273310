$(function (){

  var accordionList = $(".accordion__list");

  if (accordionList.length) {
    $('.accordion__trigger').on('click', function(e) {
        e.preventDefault();

        var
            $this = $(this),
            container = $this.closest('.accordion'),
            item = $this.closest('.accordion__item'),
            currentContent = item.find('.accordion__content')
            duration = 300;

        if (!item.hasClass('accordion__item_active')) {
            item.addClass('accordion__item_active')
            .siblings()
            .removeClass('accordion__item_active')
            .find('.accordion__content')
            .slideUp();

            currentContent.stop(true, true).slideDown(duration);
        } else {
            item.removeClass('accordion__item_active');
            currentContent.stop(true, true).slideUp(duration);
        }
      });
  };
}());
